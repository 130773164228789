import _is from "../function/is";
var exports = {};
var isFunction = _is;
var classRe = /^\s*class[\s{/}]/,
  functionToString = Function.prototype.toString;
exports = function (value) {
  if (!isFunction(value)) return false;
  if (classRe.test(functionToString.call(value))) return false;
  return true;
};
export default exports;